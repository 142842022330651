<template>
  <div>
    <div class="container black mt-7">
      <v-row>
        <v-col cols="12" sm="6">
          <v-card dark class="pa-3">
            <v-img src="/uploads/pnt3.jpg"></v-img>
            <h3 class="text-center orange--text text--darken-4 mt-2">Peanut</h3>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="card-hover pa-2 mr-2">
            <h2 class="orange--text text--darken-4">Peanut</h2>
            <h2 class="orange--text text--darken-4">Description:</h2>
            <span>
              The Peanut, also known as the groundnut, goober (US), pindar (US) or monkey nut (UK), is a legume crop grown mainly for its edible seeds.
            </span>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeanutProducts",
}
</script>
