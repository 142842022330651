<template>
  <div>
    <peanut-products></peanut-products>
  </div>
</template>

<script>
import PeanutProducts from '../components/PeanutProducts.vue'

export default {
  name: "Coal",
  components: { PeanutProducts },

}
</script>
